import React, { Component } from 'react';
import axios from 'axios';
import '.././css/style.css';

export default class BotListPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            botlist: [],
            needreply: [],
            bot: null,
            loading: false,
            mode: props.mode,
            botSelected: null
        }
        this.getActiveBOT = this.getActiveBOT.bind(this);
    }

    getActiveBOT() {
        let { mode } = this.state
        let url;

        if (mode === "messagePage") {
            url = global.config.apiDomain + "/rest/bot-manager/get-active-bot-message";
        } else if (mode === "commentuserPage") {
            url = global.config.apiDomain + "/rest/bot-manager/get-active-bot-comment-user";
        } else {
            url = global.config.apiDomain + "/rest/bot-manager/get-active-bot";
        }
        axios.post(url)
            .then(res => {
                this.setState({
                    botlist: res.data.botlist
                })
            })
    }

    handleBotClick(param) {
        // Gọi hàm từ props và truyền param nếu cần
        this.props.onBotClick(param);
        this.setState({
            botSelected: param
        })
    }

    componentDidMount() {
        this.getActiveBOT();
    }

    render() {
        const { botlist, bot, botSelected } = this.state;
        return (
            <>
                <div id="botlist">
                    <div className="col-md-2">
                        {botlist && botlist.map((item, index) => {
                            return (
                                <tr className={`flex-container ${item.id == botSelected ? 'active-bot text-white' : ''}`} onClick={() => this.handleBotClick(item.id)}>
                                    <td className="flex-item-left">
                                        <div className="uid-row" style={{ fontWeight: "bold" }}>{item.uid}</div>
                                        <div className="name-row" style={{ fontWeight: "bold" }}>{item.name}</div>
                                    </td>
                                    <td className="flex-item-right avatar-div">
                                        <img src={item.avatar} alt="avatar" className="avatar" />
                                        {item.needReply && <i className="fa-solid fa-circle-exclamation"></i>}
                                    </td>
                                </tr>
                            )
                        })}
                    </div>
                </div>
            </>
        );
    }
}

