import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';

function convertTimestampToVietnamTime(timestamp) {
    const date = new Date(timestamp);
    const options = {
        timeZone: 'Asia/Ho_Chi_Minh',
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    };

    return date.toLocaleString('vi-VN', options);
}

function SmartLookManager() {
    const [loading, setLoading] = useState(true);
    const [selectedOptions, setSelectedOptions] = useState({
        'ikara': false,
        'yokara': false,
        'newUser': false,
        'vipUser': false,
        'randomUser': false,
    });

    const handleChange = (event) => {
        const { name, checked } = event.target;
        setSelectedOptions((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const setSmartLook = async () => {
        setLoading(true);
        const res = await axios.post(global.config.apiDomain + '/rest/smartLook/set-smartLook', JSON.stringify(selectedOptions), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setLoading(false);
        if (res.data.status === '200') {
            toast.success("Set SmartLook thành công");
        } else {
            toast.error("Set SmartLook không thành công");
        }
    }


    const randomUserSmartLook = async () => {
        setLoading(true);
        const res_random_user = await axios.post(global.config.apiDomain + '/rest/smartLook/random-smartLook', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setLoading(false);
        if (res_random_user.data.status === "200") {
            toast.success("Đã random thêm 1 user");
        } else {
            toast.error("Lỗi random user");
        }
    }

    const fetchData = async () => {
        try {
            const res = await axios.get(global.config.apiDomain + '/rest/smartLook/get-smartLook', {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const { ikara, yokara, newUser, vipUser, randomUser } = res.data;
            setSelectedOptions({
                ikara: ikara,
                yokara: yokara,
                newUser: newUser,
                vipUser: vipUser,
                randomUser: randomUser
            });
        } catch (error) {
            console.error('Lỗi khi gọi API:', error);
        }
    };

    useEffect(() => {
        fetchData();
        setLoading(false);
    }, []);

    return (
        <>
            <div
                className="container"
                style={{
                    marginTop: "100px",
                    marginBottom: "100px",
                }}
            >
                <h1 className="text-center">SmartLook Manager</h1>
                <div className="filter-container">
                    <p className="text-danger">Sau khi chọn xong tệp user, nhấn SetSmartLook</p>
                </div>
                {
                    loading === true ? (
                        <h1 className='text-center'>Loading...</h1>
                    ) : (
                        <div>
                            <table
                                className="table table-bordered"
                                style={{ marginTop: "10px" }}
                            >
                                <thead className="table-primary">
                                    <tr>
                                        <td className="align-middle">
                                            <input
                                                type="checkbox"
                                                name="ikara"
                                                value="option1"
                                                checked={selectedOptions["ikara"]}
                                                onChange={handleChange}
                                            /> Ikara
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <input
                                                type="checkbox"
                                                name="yokara"
                                                value="option1"
                                                checked={selectedOptions["yokara"]}
                                                onChange={handleChange}
                                            /> Yokara
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <input
                                                type="checkbox"
                                                name="newUser"
                                                value="option1"
                                                checked={selectedOptions["newUser"]}
                                                onChange={handleChange}
                                            /> New User
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <input
                                                type="checkbox"
                                                name="vipUser"
                                                value="option2"
                                                checked={selectedOptions["vipUser"]}
                                                onChange={handleChange}
                                            /> Vip User
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <input
                                                type="checkbox"
                                                name="randomUser"
                                                value="option3"
                                                checked={selectedOptions["randomUser"]}
                                                onChange={handleChange}
                                            /> Random User
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                            <button className="btn btn-primary" onClick={setSmartLook}>
                                Set SmartLook
                            </button>
                            <button className="btn btn-success" onClick={randomUserSmartLook} style={{marginLeft:"20px"}}>
                                Random User
                            </button>
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default SmartLookManager;
